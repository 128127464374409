import { ContextValue } from "../types";

import useSetErrors from "./useSetErrors";
import useGetBox from "./useGetBox";
import useApproveBox from "./useApproveBox";
import useUpdateBook from "./useUpdateBook";
import useUnapproveBox from "./useUnapproveBox";

const hooks: { (context: ContextValue): ContextValue }[] = [
  useSetErrors,
  useGetBox,
  useApproveBox,
  useUpdateBook,
  useUnapproveBox,
];

export default hooks;
