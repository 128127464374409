import { useCallback } from "react";

interface resObj {
  success: boolean;
  errorMessage: string;
}

export default function useCheckForErrors(setErrors) {
  return useCallback(
    function checkForErrors(res: resObj) {
      if (res.success) {
        setErrors([]);
        return false;
      }
      setErrors([res.errorMessage]);
      return true;
    },
    [setErrors]
  );
}
