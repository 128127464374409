import { gql } from "@apollo/client";

export const RETURNED_BOOK_QA_BASE = gql`
  fragment ReturnedBookQABase on ReturnedBook {
    id
    boxShipmentId
    isDonation
    isExtra
    isAnotherSubscription
    boxShipmentUrl
    barcode
    book {
      id
      title
      author {
        name
      }
    }
  }
`;

export const BOX_RETURN_QA_BASE = gql`
  fragment BoxReturnQABase on BoxReturn {
    boxShipmentId
    stage
    intakeNotes
    specialReturnProcessing
    boxShipmentUrl
    userUrl
    kustomerUrl
    qaReasons
    returnedbookSet {
      ...ReturnedBookQABase
    }
  }
  ${RETURNED_BOOK_QA_BASE}
`;
