import { State } from "../types";

interface Payload {
  errors: string[];
}
/**
 * This reducer function updates the errors array
 */
export default function setErrors(state: State, payload: Payload): State {
  const { errors } = payload;
  return {
    ...state,
    errors,
    isLoading: false,
  };
}
