import * as React from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { UserProvider } from "@literati/dj-react";

import apollo from "global/apollo";
import { URLS } from "global/constants";
import QAView from "qa";
import Nav from "components/Nav";

import "@literati/dj-css";
import "./app.css";

export default function App() {
  return (
    <AppProviders>
      <Nav />
      <AppRoutes />
    </AppProviders>
  );
}

function AppRoutes() {
  return (
    <Routes>
      <Route path={`${URLS.QA}/:boxShipmentId`} element={<QAView />} />
      c-other
    </Routes>
  );
}

type AppProvidersProps = {
  children: React.ReactNode;
};

function AppProviders(props: AppProvidersProps) {
  return (
    <ApolloProvider client={apollo}>
      <BrowserRouter>
        <UserProvider
          csrfToken="csrftoken-kids"
          authBaseUrl={process.env.REACT_APP_AUTH_BASE_URL}
          autoLogout={false}
        >
          {props.children}
        </UserProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}
