import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";

import { ContextValue, ReducerType } from "../types";
import { RETURNED_BOOK_QA_BASE } from "../helpers/fragments";

interface UpdateParams {
  isDonation: boolean;
}
/**
 * This context hook add a method calling an endpoint to update
 * parameters for a returned book.
 */
export default function useUpdateBook(context: ContextValue) {
  const { setErrors, checkForErrors, dispatch } = context;

  const onError = useCallback(
    (err) => {
      setErrors([err.message]);
    },
    [setErrors]
  );

  const onCompleted = useCallback(
    function onCompleted({ qaUpdateReturnedBook: res }) {
      if (checkForErrors(res)) return;
      const { returnedBook } = res;
      dispatch({
        type: ReducerType.updateBook,
        payload: { returnedBook },
      });
    },
    [dispatch]
  );

  const [mutate] = useMutation(GQL, { onCompleted, onError });

  context.updateBook = useCallback(
    function updateBook(returnedBookId: string, params: UpdateParams) {
      const variables = {
        returnedBookId,
        ...params,
      };
      mutate({ variables });
    },
    [mutate]
  );

  return context;
}

const GQL = gql`
  mutation QAUpdateReturnedBook($returnedBookId: ID!, $isDonation: Boolean!) {
    qaUpdateReturnedBook(
      returnedBookId: $returnedBookId
      isDonation: $isDonation
    ) {
      success
      errorMessage
      errorData
      returnedBook {
        ...ReturnedBookQABase
      }
    }
  }
  ${RETURNED_BOOK_QA_BASE}
`;
