import * as React from "react";
import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";

import useContextController from "global/hooks/useContextController";

import { ContextValue, State } from "./types";
import defaultState from "./constants";
import reducerFunctions from "./reducers";
import hooks from "./hooks";

type ProviderProps = {
  children: React.ReactNode;
};

export function ViewProvider(props: ProviderProps) {
  const { children } = props;

  const { boxShipmentId } = useParams();

  // set this initial state values
  // this can be supplemented with values from props
  // or any other source, like url params
  const initialState = defaultState({
    boxShipmentId,
  });
  const value: ContextValue = useContextController<State, ContextValue>(
    initialState,
    reducerFunctions,
    hooks
  );

  return <Provider value={value}>{children}</Provider>;
}

/**
 * A convenience hook for accessing the context value
 */
export default function useView() {
  return useContext(ViewContext);
}

const ViewContext = createContext<ContextValue>({} as ContextValue);

const { Provider } = ViewContext;
