import { useReducer } from "react";

import { useRefFromState } from "global/hooks/state";

/**
 * A reusable hook for managing state and methods available via a context provider
 *
 * @param initialState
 * @param reducerMap
 * @param methodHooks
 * @returns
 */
export default function useContextController<State, ContextValue>(
  initialState: State,
  reducerMap: {
    [key: string | number]: (state: State, payload: object) => State;
  },
  methodHooks: { (context: ContextValue): ContextValue }[]
): ContextValue {
  /**
   * A simple reducer function that maps the state and payload a reducer function
   * defines in reducerMap
   */
  function reducer(state: State, action): State {
    const { type, payload } = action;
    return reducerMap[type](state, payload);
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  // adds the current state to ref.current any time state changes
  // so functions can easily access values of the current state
  const stateRef = useRefFromState<State>(state);

  let contextValue = {
    ...state,
    dispatch,
    state,
    stateRef,
  } as unknown as ContextValue;

  // Here we're looping over our method hooks so
  // so each methods hook can utilize the methods defined before it
  methodHooks.forEach((hook) => {
    contextValue = hook(contextValue);
  });

  // state and methods are made available to UI components
  return contextValue;
}
