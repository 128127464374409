import * as React from "react";
import { ViewProvider } from "./useQAReturn";
import View from "./QAView";

export default function QAView() {
  return (
    <ViewProvider>
      <View />
    </ViewProvider>
  );
}
