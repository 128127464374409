import { useState, useRef, useEffect } from "react";

export function useRefFromState<T>(state: T) {
  const stateRef = useRef<T>(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  return stateRef;
}

export function useStateRef(initialValue) {
  const [state, setState] = useState(initialValue);
  const stateRef = useRefFromState(state);
  return [state, setState, stateRef];
}
