import { State, ReturnedBook } from "../types";

interface Payload {
  returnedBook: ReturnedBook;
}
/**
 * This reducer function replaces the stored object for a returned book
 */
export default function updateBook(state: State, payload: Payload): State {
  const { returnedBook } = payload;
  const returnedBooks = [...state.returnedBooks];

  // replace the book data
  const index = returnedBooks.findIndex((rb) => rb.id === returnedBook.id);
  returnedBooks[index] = returnedBook;

  return {
    ...state,
    returnedBooks,
  };
}
