import { State, BoxReturn, ReturnedBook } from "../types";
interface Payload {
  boxReturn: BoxReturn & {
    returnedbookSet: ReturnedBook[];
  };
}
/**
 * This reducer function updates the state for the boxReturn and it's books
 */
export default function setBoxReturn(state: State, payload: Payload): State {
  const { returnedbookSet: returnedBooks, ...boxReturn } = payload.boxReturn;

  return {
    ...state,
    boxReturn,
    returnedBooks,
    isLoading: false,
  };
}
