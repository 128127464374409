import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";

import { ContextValue, ReducerType } from "../types";
import { BOX_RETURN_QA_BASE } from "../helpers/fragments";

/**
 * Add a method to update the box as unapproved.
 */
export default function useUnapproveBox(context: ContextValue) {
  const { stateRef, setErrors, checkForErrors, dispatch } = context;

  const onError = useCallback(
    (err) => {
      setErrors([err.message]);
    },
    [setErrors]
  );

  const onCompleted = useCallback(
    function onCompleted({ qaUnapproveBoxReturn: res }) {
      if (checkForErrors(res)) return;
      const { boxReturn } = res;
      dispatch({
        type: ReducerType.setBoxReturn,
        payload: { boxReturn },
      });
    },
    [dispatch, checkForErrors]
  );

  const [mutate] = useMutation(GQL, { onCompleted, onError });

  context.unapproveBox = useCallback(
    function approveBox() {
      const { boxShipmentId } = stateRef.current;
      const variables = {
        boxReturnId: boxShipmentId,
      };
      mutate({ variables });
    },
    [mutate, stateRef]
  );

  return context;
}

const GQL = gql`
  mutation QAUnapproveBoxReturn($boxReturnId: ID!) {
    qaUnapproveBoxReturn(boxReturnId: $boxReturnId) {
      success
      errorMessage
      errorData
      boxReturn {
        ...BoxReturnQABase
      }
    }
  }
  ${BOX_RETURN_QA_BASE}
`;
