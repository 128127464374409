export enum ReducerType {
  setBoxReturn,
  setErrors,
  updateBook,
}

export interface Methods {
  setErrors(errors: string[]): void;
  checkForErrors(res: object): boolean;
  approveBox(): void;
  unapproveBox(): void;
  updateBook(returnedBookId: string, params: object): void;
}

interface Author {
  name: string;
}
interface Book {
  id: string;
  title: string;
  author: Author;
}

export interface ReturnedBook {
  id: string;
  boxShipmentId?: string;
  boxShipmentUrl?: string;
  book: Book;
  barcode: string;
  isDonation: boolean;
  isExtra: boolean;
  isAnotherSubscription: boolean;
}

export interface BoxReturn {
  boxShipmentId: string;
  stage: string;
  hasExtraBooks: boolean;
  isLate: boolean;
  hasExistingInvoice: boolean;
  intakeNotes: string;
  specialReturnProcessing: string;
  qaReasons: string[];
  boxShipmentUrl: string;
  userUrl: string;
  kustomerUrl: string;
}

export interface State {
  boxShipmentId?: string;
  boxReturn?: BoxReturn;
  returnedBooks: ReturnedBook[];
  isLoading: boolean;
  errors: string[];
}

export interface StateRef {
  current: State;
}

export interface ReducerAction {
  type: ReducerType;
  payload?: object;
}

export type Dispatch = (a: ReducerAction) => void;

export type ContextValue = State &
  Methods & {
    state: State;
    stateRef: StateRef;
    dispatch: Dispatch;
    methods: Methods;
  };
