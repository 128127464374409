import { State } from "./types";

export default function defaultState(overrides: object = {}): State {
  return {
    errors: [],
    boxShipmentId: null,
    boxReturn: null,
    returnedBooks: [],
    isLoading: true,
    ...overrides,
  };
}
