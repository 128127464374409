import { useCallback } from "react";

import useCheckForErrors from "global/hooks/useCheckForErrors";
import { ContextValue, ReducerType } from "../types";

/**
 * This context hook adds a method for setting errors
 * and a method for checking graphql response objects for errorMessages
 */
export default function useSetErrors(context: ContextValue): ContextValue {
  const { dispatch } = context;

  const setErrors = useCallback(
    function setErrors(errors: string[]) {
      dispatch({
        type: ReducerType.setErrors,
        payload: {
          errors,
        },
      });
    },
    [dispatch]
  );
  const checkForErrors = useCheckForErrors(setErrors);

  return {
    ...context,
    setErrors,
    checkForErrors,
  };
}
