import { ReducerType } from "../types";

import setBoxReturn from "./setBoxReturn";
import setErrors from "./setErrors";
import updateBook from "./updateBook";

const reducerFunctions = {
  [ReducerType.setErrors]: setErrors,
  [ReducerType.setBoxReturn]: setBoxReturn,
  [ReducerType.updateBook]: updateBook,
};

export default reducerFunctions;
