import { useCallback, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";

import { ContextValue, ReducerType } from "../types";
import { BOX_RETURN_QA_BASE } from "../helpers/fragments";

/**
 * This context hook is responsible for fetching the BoxReturn object
 * when this provider first mounts.
 */
export default function useGetBox(context: ContextValue) {
  const { stateRef, setErrors, checkForErrors, dispatch } = context;

  const onError = useCallback(
    (err) => {
      setErrors([err.message]);
    },
    [setErrors]
  );
  const onCompleted = useCallback(
    function onCompleted({ qaBoxReturn: res }) {
      if (checkForErrors(res)) return;
      const { boxReturn } = res;
      dispatch({
        type: ReducerType.setBoxReturn,
        payload: { boxReturn },
      });
    },
    [dispatch, checkForErrors]
  );

  const [mutate] = useMutation(GQL, {
    onCompleted,
    onError,
    fetchPolicy: "no-cache",
  });

  const getBox = useCallback(() => {
    const { boxShipmentId } = stateRef.current;

    const variables = {
      boxReturnId: boxShipmentId,
    };
    mutate({ variables });
  }, [mutate, stateRef]);

  // call it once
  useEffect(getBox, []);

  return context;
}

const GQL = gql`
  mutation QABoxReturn($boxReturnId: ID!) {
    qaBoxReturn(boxReturnId: $boxReturnId) {
      success
      errorMessage
      errorData
      boxReturn {
        ...BoxReturnQABase
      }
    }
  }
  ${BOX_RETURN_QA_BASE}
`;
