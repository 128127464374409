import * as React from "react";

import {
  Hero,
  LinkWithIcon,
  Tag,
  Card,
  CardContent,
  CardHeader,
  Button,
  Icon,
} from "@literati/dj-react";

import useQAReturn from "./useQAReturn";
import { ReturnedBook as ReturnedBookType } from "./useQAReturn/types";

export default function QAView() {
  const { isLoading, boxReturn } = useQAReturn();

  if (isLoading) return <div>Loading...</div>;
  if (!boxReturn) return <Errors />;

  return (
    <>
      <ViewHero />
      <div className="container">
        <div className="side-area flex-column">
          <Errors />
          <Reasons />
          <ApproveButton />
          <UnapproveButton />
          <IntakeReturnsNotes />
          <SpecialReturnsNotes />
        </div>
        <div className="main-area flex-column">
          <BookGroups />
        </div>
      </div>
    </>
  );
}

function ViewHero() {
  const { boxShipmentId, boxReturn } = useQAReturn();

  return (
    <Hero className="hero-split">
      <div className="flex-row">
        <h1>Box #{boxShipmentId}</h1>
        <StageTag />
      </div>
      <div className="flex-row">
        <LinkWithIcon
          icon="kustomer"
          label="Attached Kustomer Ticket"
          href={boxReturn.kustomerUrl}
        />
        <LinkWithIcon
          icon="box"
          label="Box Shipment in Literati"
          href={boxReturn.boxShipmentUrl}
        />
        <LinkWithIcon
          icon="user"
          label="Customer in Literati"
          href={boxReturn.userUrl}
        />
      </div>
    </Hero>
  );
}

function StageTag() {
  const { boxReturn } = useQAReturn();
  if (!boxReturn) return null;
  const { stage } = boxReturn;
  let variant = "danger";
  if (stage === "qa-queue") variant = "info";
  else if (stage === "approved") variant = "success";
  return <Tag variant={variant}>{stage}</Tag>;
}

function Reasons() {
  const { boxReturn } = useQAReturn();
  const { qaReasons = [] } = boxReturn;
  return (
    <div>
      <h2>Reasons for QA</h2>
      <div className="flex-group">
        {qaReasons.map((reason) => (
          <Tag variant="info" key={reason}>
            {reason}
          </Tag>
        ))}
        {!qaReasons.length && "No Issues"}
      </div>
    </div>
  );
}

/**
 * Displays notes set by a CS agent.
 */
function SpecialReturnsNotes() {
  const { boxReturn } = useQAReturn();

  if (!boxReturn?.specialReturnProcessing) return null;

  return (
    <>
      <Card style={{ width: "100%" }}>
        <CardHeader title="Special Returns Processing Notes" />
        <CardContent>{boxReturn.specialReturnProcessing}</CardContent>
      </Card>
    </>
  );
}

/**
 * Displays notes set by a CS agent.
 */
function IntakeReturnsNotes() {
  const { boxReturn } = useQAReturn();

  if (!boxReturn?.intakeNotes) return null;

  return (
    <>
      <Card style={{ width: "100%" }}>
        <CardHeader title="Intake Notes" />
        <CardContent>{boxReturn.intakeNotes}</CardContent>
      </Card>
    </>
  );
}

function ApproveButton() {
  const { approveBox, boxReturn } = useQAReturn();
  const { stage } = boxReturn;
  if (stage !== "qa-queue") return null;
  return <Button onClick={() => approveBox()}>Approve</Button>;
}

function UnapproveButton() {
  const { unapproveBox, boxReturn } = useQAReturn();
  const { stage } = boxReturn;
  if (stage !== "approved") return null;
  return (
    <Button onClick={() => unapproveBox()} variant="danger">
      Unapprove
    </Button>
  );
}

enum GROUPS {
  THIS_BOX,
  OTHER_BOX,
  OTHER_SUB,
  UNKNOWN,
}

function BookGroups() {
  const { returnedBooks } = useQAReturn();

  if (!returnedBooks.length) return <div>No Books</div>;

  // group returned books by tote name
  const groupedBooks: { [title: string]: ReturnedBookType[] } = {};

  returnedBooks.forEach((rb) => {
    let group = GROUPS.THIS_BOX;
    if (!rb.boxShipmentId) group = GROUPS.UNKNOWN;
    else if (rb.isAnotherSubscription) group = GROUPS.OTHER_SUB;
    else if (rb.isExtra) group = GROUPS.OTHER_BOX;
    // add the book to the selected group
    const books = groupedBooks[group] || [];
    books.push(rb);
    groupedBooks[group] = books;
  });

  return (
    <>
      <BookGroup
        title={"From This Box"}
        returnedBooks={groupedBooks[GROUPS.THIS_BOX]}
      />
      <BookGroup
        title={"From Previous Box"}
        returnedBooks={groupedBooks[GROUPS.OTHER_BOX]}
      />
      <BookGroup
        title={"From Within Account"}
        returnedBooks={groupedBooks[GROUPS.OTHER_SUB]}
      />
      <BookGroup
        title={"Unknown Origin"}
        returnedBooks={groupedBooks[GROUPS.UNKNOWN]}
      />
    </>
  );
}

interface BookGroupProps {
  title: string;
  returnedBooks?: ReturnedBookType[];
}

function BookGroup(props: BookGroupProps) {
  const { title, returnedBooks } = props;

  if (!returnedBooks) return null;

  return (
    <div>
      <h2>{title}</h2>
      <table style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th>Book</th>
            <th>Author</th>
            <th>Original Box</th>
            <th>Accounting Status</th>
          </tr>
        </thead>
        <tbody>
          {returnedBooks.map((rb) => (
            <ReturnedBook key={rb.id} returnedBook={rb} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

interface ReturnedBookProps {
  returnedBook: ReturnedBookType;
}

function ReturnedBook(props: ReturnedBookProps) {
  const { returnedBook } = props;

  const { isDonation } = returnedBook;

  const { updateBook } = useQAReturn();

  function setDonation(isDonation) {
    updateBook(returnedBook.id, { isDonation });
  }

  return (
    <tr>
      <td>{returnedBook.book?.title || returnedBook.barcode}</td>
      <td>{returnedBook.book?.author.name || "unknown"}</td>
      <td>
        <a
          target="_blank"
          rel="noreferrer"
          href={returnedBook.boxShipmentUrl}
          className="flex-center"
        >
          Literate Box #{returnedBook.boxShipmentId}{" "}
          <Icon style={{ width: "15px" }} name="external-link" />
        </a>
      </td>
      <td>
        <Tag
          className="pointer"
          onClick={() => setDonation(!isDonation)}
          variant={isDonation ? "warning" : "success"}
        >
          {isDonation ? "Donation" : "Return"}
        </Tag>
      </td>
    </tr>
  );
}

function Errors() {
  const { errors } = useQAReturn();
  if (!errors.length) return null;
  return (
    <div>
      Errors:
      <ul>
        {errors.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </div>
  );
}
