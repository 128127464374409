import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export const csrftoken = getCookie("csrftoken-kids") || getCookie("csrftoken");

const link = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URL,
  credentials: "include",
  headers: {
    "X-CSRFToken": csrftoken,
  },
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
